<template>
  <div
    class="px-0 container-fluid h-100"
    style="text-align: left">
    <div class="container mt-3">
      <BreadCrumbComponent :pageTitles="['Twitter Posts']" />
    </div>
    <article class="mt-3">
      <section>
        <div class="container">
          <div class="row w-100">
            <div class="text-left col-12">
              <div style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                ">
                <strong>Twitter Posts</strong><br />
              </div>
            </div>
          </div>
          <div class="row w-100">
            <div class="col-12">
              <AddTwitterPostDialog @re-fresh="fetchAllTwitterPosts"
                style="float: right !important" />
            </div>
          </div>
          <hr />
        </div>
      </section>
    </article>

    <section>
      <!--- SHOW THIS ARTICLE WHEN FETCHING TEAMS FROM THE DATABASE HAS FAILED --->
      <article v-if="isLoadingError"
        class="d-flex align-items-center justify-content-center"
        style="height: 180px">
        <div class="text-center">
          <div style="font-weight: 600; margin-top: 50px">Fetching Failed</div>
          <div class="pt-2"
            style="font-weight: 400; font-size: 0.9em">
            Failed to fetch tweets now. Please try again
          </div>
          <button type="button"
            class="mx-0 mt-3 btn z-depth-0"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            @click="fetchAllTwitterPosts"
            style="
              width: 220px;
              text-transform: capitalize;
              height: 45px;
              border-radius: 4px;
              background-color: red;
              font-weight: 500;
            ">
            <span style="position: relative; bottom: 1px"><i class="pr-2 fas fa-refresh"></i> Try Again</span>
          </button>
        </div>
      </article>

      <article v-show="loading && !isLoadingError">
        <div class="container">
          <div class="row w-100 mx-0 px-0">
            <div v-for="i in 3"
              :key="i"
              class="tweet-card position-relative pl-2 pt-1">
              <div class="our_team_container_content">
                <div>
                  <div class="publish_content">
                    <div class="tweet-placeholder"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>

      <!--- SHOW THIS ARTICLE WHEN TEAMS ARE FETCHED FROM THE SERVER --->
      <article v-if="!loading && !isLoadingError">
        <div class="container">
          <div class="row w-100 mx-0 px-0">
            <div v-for="tweet in tweets"
              :key="tweet.website_twitter_post_id"
              class="tweet-card position-relative pl-2 pt-1">
              <div class="our_team_container_content">
                <div>
                  <div class="publish_content">
                    <Tweet :id="tweet.tweet_id"
                      :options="tweetOptions">
                      <div class="tweet-placeholder"></div>
                    </Tweet>
                  </div>
                </div>
              </div>
              <div class="tweet-btn">
                <el-tooltip content="Move up"
                  placement="top">
                  <el-button type="info"
                    icon="el-icon-top"
                    circle
                    size="mini"
                    :disabled="tweet.orderIndex == 0"
                    @click="moveTweetUp(tweet.website_twitter_post_id, tweet.orderIndex)"></el-button>
                </el-tooltip>
                <el-tooltip content="Move Down"
                  placement="top">
                  <el-button type="info"
                    icon="el-icon-bottom"
                    size="mini"
                    circle
                    :disabled="tweet.orderIndex == (tweets.length - 1)"
                    @click="moveTweetDown(tweet.website_twitter_post_id, tweet.orderIndex)"></el-button>
                </el-tooltip>
                <el-button type="danger"
                  icon="el-icon-delete"
                  circle
                  size="small"
                  @click="deleteTwitterPost(tweet.website_twitter_post_id)"></el-button>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import { Tweet } from "vue-tweet-embed";
import BreadCrumbComponent from "../components/bread-crumb-component";
import AddTwitterPostDialog from "@/components/dialogs/add-twitter-post-dialog";
export default {
  components: {
    AddTwitterPostDialog,
    BreadCrumbComponent,
    Tweet,
  },

  data() {
    return {
      loading: true,
      isLoadingError: false,
      tweets: [],
      tweetOptions: {
        /* cards: 'hidden', */ hide_thread: "true",
        "data-conversation": "none",
      },
    };
  },

  mounted() {
    this.fetchAllTwitterPosts();
  },

  methods: {
    async fetchAllTwitterPosts() {
      let request = await this.httpRequest({
        method: "GET",
        url: "website/twitter-posts",
        loadingPropertyName: "loading",
        errorLoadingPropertyName: "isLoadingError",
      });

      if (
        request &&
        request.success &&
        request.message == "Twitter posts fetched successfully"
      ) {
        this.tweets = request.data.map((item, index) => {
          return {
            website_twitter_post_id: item.website_twitter_post_id,
            tweet_id: item.tweet_id,
            orderIndex: index
          }
        });
      }
    },

    async deleteTwitterPost(tweetId) {
      await this.$confirm(
        "This will permanently delete this Tweet. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );

      let request = await this.httpRequest({
        method: "DELETE",
        url: `website/twitter-posts/${tweetId}`,
        loadingPropertyName: "loading",
        errorLoadingPropertyName: "isLoadingError",
      });

      if (
        request &&
        request.success &&
        request.message == "Twitter post deleted successfully"
      ) {
        this.fetchAllTwitterPosts();
        return this.showSuccessMessage(
          "Deleted Successfully",
          "The Tweet was successfully deleted"
        );
      }
    },

    moveTweetUp(tweetId, tweetOrderIndex) {
      // get previous twee as we want to swap with it
      const affectedTweet = this.tweets.filter(item => item.website_twitter_post_id == tweetId)[0];
      const currentIndex = affectedTweet.orderIndex;

      const prevTweet = this.tweets.filter(item => item.orderIndex == (tweetOrderIndex - 1))[0]

      // swap with previous tweet
      affectedTweet.orderIndex = prevTweet.orderIndex
      prevTweet.orderIndex = currentIndex

      this.saveTweetsOrder()
    },

    moveTweetDown(tweetId, tweetOrderIndex) {
      // get previous tweet as we want to swap with it
      const affectedTweet = this.tweets.filter(item => item.website_twitter_post_id == tweetId)[0];
      const currentIndex = affectedTweet.orderIndex;

      const prevTweet = this.tweets.filter(item => item.orderIndex == (tweetOrderIndex + 1))[0]

      // swap with previous tweet
      affectedTweet.orderIndex = prevTweet.orderIndex
      prevTweet.orderIndex = currentIndex

      this.saveTweetsOrder()
    },

    async saveTweetsOrder() {
      let request = await this.httpRequest({
        method: "PATCH",
        url: "website/twitter-posts/save-order",
        loadingPropertyName: "loading",
        errorLoadingPropertyName: "isLoadingError",
        body: {
          items: this.tweets.map(item => {
            return {
              id: item.website_twitter_post_id,
              index: item.orderIndex
            }
          })
        },
      });

      if (
        request &&
        request.success &&
        request.message == "Twitter posts order saved successfully"
      ) {
        this.showSuccessMessage(
          "Tweets order updated",
          "Tweets order successfully updated"
        );
        this.fetchAllTwitterPosts();
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.tweet-placeholder {
  height: 400px;
  width: 340px;
  background-color: #ebeef4;
  padding: 0 auto;
}

.tweet-btn {
  display: none;
}

.tweet {
  width: 340px;
}

.tweet-card:hover .tweet-btn {
  display: block;
  position: absolute;
  top: 20px;
  right: 4px;
  padding: 5px;
}

.publishing_status {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
</style>

<style>
.publishing_status .el-button {
  border-radius: 5px 0px 0px 5px;
}
</style>
