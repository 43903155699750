<template>
  <div>
    <article>
      <el-button
        style="
          background-color: var(--el-app-primary);
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
        "
        class="z-depth-0"
        v-ripple="'rgba(255, 255, 255, 0.35)'"
        @click="showModal"
      >
        Add Twitter Post
      </el-button>

      <section>
        <div class="container">
          <div class="batch_dialogs">
            <el-dialog
              title="Add Twitter Post"
              :visible.sync="addTweetDialog"
              :destroy-on-close="true"
              :before-close="handleClose"
              width="35%"
            >
              <div v-loading="loading" class="">
                <div class="row">
                  <div class="col-md-12">
                    <label>Twitter Share Url</label>
                    <el-input v-model="twitterShareUrl" placeholder="Twitter Share Url"></el-input>
                  </div>
                </div>

                <div class="form-group text-center">
                  <el-button
                    style="
                      background-color: var(--el-app-primary);
                      color: white;
                    "
                    v-ripple="'rgba(255, 255, 255, 0.35)'"
                    class="z-depth-0 mt-4"
                    @click="addTwitterPost"
                  >
                    Add Post
                  </el-button>
                </div>

                <hr />

                <div class="text-center">
                  <div class="text-center">
                    <small></small>
                  </div>
                  <div class="text-center"></div>
                </div>
              </div>
            </el-dialog>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addTweetDialog: false,
      loading: false,
      loadingError: false,
      twitterShareUrl: "",
    };
  },

  methods: {
    clearInputs() {
      this.twitterShareUrl = "";
    },
    // _____ close modal
    handleClose(value) {
      value();
      this.clearInputs();
    },

    showModal() {
      this.addTweetDialog = true;
    },

    async addTwitterPost() {
      if (this.twitterShareUrl === "") {
        return this.showWarningMessage(
          "Missing link",
          "Please provide the Twitter Share Url"
        );
      }

      let request = await this.httpRequest({
        method: "POST",
        url: "website/twitter-posts/add",
        loadingPropertyName: "loading",
        errorLoadingPropertyName: "loadingError",
        body: {
          tweet_share_url: this.twitterShareUrl,
        },
      });

      if (
        request &&
        request.success &&
        request.message == "Twitter post added successfully"
      ) {
        this.showSuccessMessage(
          "Tweet Added",
          "The Twitter post was successfully added."
        );
        this.addTweetDialog = false;
        this.clearInputs();
        this.$emit("re-fresh");
      }
    },
  },
};
</script>

<style scoped>
</style>
